import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Async from 'react-async';
import { Alert, Container, Spinner, Navbar, Nav } from 'react-bootstrap';
import PrivacyForm from './PrivacyForm.js';
import './App.scss';


function App(props) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split("-")[0].toLowerCase()
  const loadRequest = useCallback(async () => {
    return fetch("/req/" + props.match.params.ticket_id + "?lang=" + lang, { "method" : "GET" })
      .then(res => res.ok ? res.json().then(data => {
            if (data.skip === true)
              window.location.href = data.redirect_endpoint
            return data;
          }) : res.text().then(errorMessage => Promise.reject(new Error(errorMessage)))
      )
  }, [props.match.params.ticket_id, i18n.language])



  useEffect(() => {
    document.title = t("title")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ i18n.language ]);

  return (
    <div className="wrapper">
      <script src="https://unpkg.com/react/umd/react.production.min.js" crossOrigin="anonymous"></script>

      <header>
        <div id="logos" role="banner">
          <a href={t("header-home-link")} className="wwulogo svg">
            <img src="https://www.uni-muenster.de/imperia/md/images/allgemein/farbunabhaengig/wwu.svg" width="332" height="96" alt="Uni Münster" id="logo" className="university-logo svg" />
          </a>
        </div>
        <div className="topnavbar">
          &nbsp;
          <div className="language-selector">
            {lang == "de" ? <b>DE</b> : <button onClick={() => i18n.changeLanguage('de')} title="Deutsch">DE</button>} | {lang == "en" ? <b>EN</b> : <button onClick={() => i18n.changeLanguage('en')} title="English">EN</button>}
          </div>
        </div>
      </header>

      <div className="content">
        <Async promiseFn={loadRequest}>
            <Async.Loading>
              <Container>
                <Spinner animation="border" role="status">
                  <span className="sr-only">{t("loading")}</span>
                </Spinner>                
              </Container>
            </Async.Loading>
            <Async.Fulfilled>
              { data => <PrivacyForm data={data} ticketId={props.match.params.ticket_id} /> }
            </Async.Fulfilled>
            <Async.Rejected>
              { error => <Alert variant="danger">{t("error-loading-data")}: {error.message}</Alert> }
            </Async.Rejected>
        </Async>
      </div>

      <footer>
        <Navbar bg="light" className="text-uppercase">
          <Nav className="mr-auto">
            <Nav.Link href={t("footer-impressum-link")}>{t("footer-impressum")}</Nav.Link>
            <Nav.Link href={t("footer-privacypolicy-link")}>{t("footer-privacypolicy")}</Nav.Link>
          </Nav>
          <Navbar.Brand>{t("footer-copyright")}</Navbar.Brand>
        </Navbar>

      </footer>
    </div>
  );
}

export default App
