import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Async from 'react-async';
import { Alert, Form, Container, Spinner, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useUID } from 'react-uid';



function PrivacyPolicy(props) {
  const { t, i18n } = useTranslation();

  const [agreed, setAgreed] = useState(false)
  const prevAgreed = useRef();
  const [data, setData] = useState({})

  const onChangeHandler = e => {
    setAgreed(e.target.checked);
  }

  useEffect(() => {
    if (prevAgreed.current !== agreed) {
      props.onChange(agreed)
    }
    prevAgreed.current = agreed
  })

  const uid = useUID();

  return (
    <Form.Group as={Row}>
      <Form.Label column="lg" sm="2">{props.label}</Form.Label>
      <Col sm="10">
        <Form.Text as="div" style={{ height: "20em", overflowY: "scroll" }}>
          <ReactMarkdown className="policy-markdown" source={props.policy} />
        </Form.Text>
        {props.checkbox ?
          <Form.Check inline style={{ marginTop: "1.0em", fontSize: "1.1em" }} id={uid}>
            <Form.Check.Input type="checkbox" checked={agreed} onChange={onChangeHandler} />
            <Form.Check.Label>{t("privacypolicy-accept")}<font color="#ff0000"></font></Form.Check.Label>
          </Form.Check> : ""
        }
      </Col>
    </Form.Group>
  )
}

export default PrivacyPolicy;
