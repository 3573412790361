  
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
    de: {
      translation: {
        "title" : "Zustimmung zu Datenübertragung, Benutzungsbedingungen und Datenschutzerklärung",
        "loading": "Lade...",
        "error-loading-data": "Fehler beim Laden der Daten",
        "header-home-link": "https://www.uni-muenster.de/de",
        "footer-impressum": "Impressum",
        "footer-privacypolicy": "Datenschutzerklärung",
        "footer-copyright": "©  2020 Universität Münster",
        "footer-impressum-link": "https://www.uni-muenster.de/de/impressum.html",
        "footer-privacypolicy-link": "https://www.uni-muenster.de/de/datenschutzerklaerung.html",
        "privacyform-consent": "Zustimmung zu Datenübertragung, Benutzungsbedingungen und Datenschutzerklärung für Dienst",
        "privacyform-submit": "Einverstanden",
        "privacyform-error-submitting": "Fehler beim Übertragen der Formulardaten",
        "privacyform-terms": "Benutzungs\u00ADbedingungen",
        "privacyform-policy": "Daten\u00ADschutz\u00ADerklärung",
        "privacypolicy-accept": "Ich verstehe und akzeptiere den Inhalt der Datenschutzerklärung",
        "attributes-to-service": "Die folgenden Attribute werden zum Dienst weitergeleitet:",
        "attributes-attributes": "Attribute",
        "attributes-attribute": "Attribut",
        "attributes-value": "Wert",
      }
    },
    en: {
      translation: {
        "title" : "Consent to data submission, usage terms and privacy policy",
        "loading": "Loading...",
        "error-loading-data": "Error receiving data",
        "header-home-link": "https://www.uni-muenster.de/en",
        "footer-impressum": "Impressum",
        "footer-privacypolicy": "Privacy Policy",
        "footer-copyright": "©  2020 University of Münster",
        "footer-impressum-link": "https://www.uni-muenster.de/de/en/impressum.html",
        "footer-privacypolicy-link": "https://www.uni-muenster.de/de/en/datenschutzerklaerung.html",
        "privacyform-consent": "Consent to data submission, usage terms and privacy policies for service",
        "privacyform-submit": "Submit",
        "privacyform-error-submitting": "Error submitting form data",
        "privacyform-terms": "Terms and Conditions",
        "privacyform-policy": "Privacy Policy",
        "privacypolicy-accept": "I understand and accept the contents of the privacy policy",
        "attributes-to-service": "The following attributes will be sent to the service:",
        "attributes-attributes": "Attributes",
        "attributes-attribute": "Attribute",
        "attributes-value": "Value",
      }
    }
  };


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    load : "languageOnly",
    fallbackLng : "de",
    debug : true,

    interpolation : {
      escapeValue : false, // not needed for react as it escapes by default
    },
  });

export default i18n;
