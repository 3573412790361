import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Table, Row, Col } from 'react-bootstrap';

function Attributes(props) {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row}>
    <Form.Label column="lg" sm="2">{t("attributes-attributes")}</Form.Label>
    <Col sm="10">
        <Form.Text as="div">
        {t("attributes-to-service")}
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>{t("attributes-attribute")}</th>
                <th>{t("attributes-value")}</th>
            </tr>
            </thead>
            <tbody>
            { Object.entries(props.attributes).map(([key, value], i) => (
                <tr key={i}>
                    <td>{key}</td>
                    <td>{Array.isArray(value) ? value.map((value, i) => (<div key={i}>{value}</div>)) : value }</td>
                </tr>
                ))
            }
            </tbody>
        </Table>
        </Form.Text>
    </Col>
    </Form.Group>
  )
}

export default Attributes
